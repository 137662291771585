import styled from "styled-components";
export const Wrapper = styled.div`
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ajusta según sea necesario */
  }

  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top: 8px solid #3498db; /* Color del spinner */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }

  /* Animación de giro */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const WrapperLoading = styled.div`
  .loading-container {
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh; /* Centra el texto verticalmente */
    color: ${({ theme }) => (theme === "dark" ? "#fff" : "#000000")};
  }

  .dot {
    animation: blink 1.4s infinite both;
  }

  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;
