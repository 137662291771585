import React from "react";
import { Wrapper } from "./Styles";

const Loader = () => {
  return (
    <Wrapper>
      <div className="loader-container">
        <div className="spinner" />
      </div>
    </Wrapper>
  );
};

export default Loader;
