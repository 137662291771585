import React, { lazy, Suspense, useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Loader from "./components/loader/Loader";
import { useSelector } from "react-redux";
const AuthRedirect = lazy(() => import("./pages/redirect/AuthRedirect"));
const Home = lazy(() => import("./pages/home/Home"));
const Signin = lazy(() => import("./pages/authentication/signin/Signin"));
const Signup = lazy(() => import("./pages/authentication/signup/Signup"));
const ChangePasswordView = lazy(() =>
  import("./pages/authentication/change-password/ChangePasswordView")
);
const ResetPassswordView = lazy(() =>
  import("./pages/authentication/change-password/ResetPassswordView")
);
const NotFound = lazy(() => import("./pages/not-found/NotFound"));
const DashboardLayout = lazy(() => import("./layouts/DashboardLayout"));
const TransactionMortgage = lazy(() =>
  import("./pages/transaction-morgage/TransactionMortgage")
);
const TermsAndConditions = lazy(() =>
  import("./pages/terms-and-conditions/TermsAndConditions")
);
const PrivacityPolicy = lazy(() =>
  import("./pages/privacy-police/PrivacityPolice")
);

const App = () => {
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (auth?.user?.theme) {
      document.documentElement.setAttribute("data-theme", auth.user.theme);
    }
  }, [auth.user]);
  return (
    <Suspense fallback={<Loader />}>
      <AuthRedirect />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/transaction-mortgage-purchase"
          element={<TransactionMortgage />}
        />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassswordView />} />
        <Route
          path="/change-password/:token"
          element={<ChangePasswordView />}
        />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacityPolicy />} />
        <Route path="/dashboard/*" element={<DashboardLayout />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default App;
